import React from 'react'
import { navigate } from 'gatsby'
import { Form, Formik } from 'formik'
import { useQuery, useMutation } from '@apollo/client'
import { Grid } from '@material-ui/core'
import { Panel } from '@clubspark-react/clubspark-react-tools'
import Button from 'src/components/button/button'
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs'
import SchoolForm from 'src/components/school-form/school-form'
import useSchoolValidations, {
  defaultValues,
  SchoolFormValues
} from 'src/components/school-form/school-validation'
import { GET_SCHOOL, EDIT_SCHOOL } from 'src/components/schools/school-queries'
import * as styles from './edit-school.module.less'
import Spinner from 'src/components/spinner/spinner'
import APIErrorMessage from 'src/components/api-error-message/api-error-message'
import { useTranslation } from 'react-i18next'

interface EditSchoolProps {
  schoolId?: string
}

const EditSchool: React.FC<EditSchoolProps> = ({ schoolId }) => {
  const { t } = useTranslation()
  const { data: schoolData, loading: loadingSchool } = useQuery<{
    school: SchoolFormValues | null
  }>(GET_SCHOOL, {
    fetchPolicy: 'no-cache',
    variables: { id: schoolId }
  })
  const [editSchool, { loading: savingSchool, error: editSchoolError }] = useMutation(EDIT_SCHOOL)

  const validationSchema = useSchoolValidations()

  // eslint-disable-next-line
  const { id = '', __typename = '', ...school } = schoolData?.school || {}

  const existingSchool: SchoolFormValues = Object.assign(defaultValues, school)
  const addingSchoolErrorMessage = editSchoolError?.graphQLErrors[0]?.message

  const navigateToSchools = () => navigate('/schools')

  const handleSubmit = (editSchoolInput: SchoolFormValues) => {
    const editSchoolInputCopy = { ...editSchoolInput }
    delete editSchoolInputCopy.manId
    delete editSchoolInputCopy.womanId

    editSchool({
      variables: {
        id: schoolId,
        editSchoolInput: editSchoolInputCopy
      }
    }).then(() => {
      navigateToSchools()
    })
  }

  if (loadingSchool) {
    return <Spinner />
  }

  return (
    <Grid container direction="column">
      <Breadcrumbs
        paths={[
          { name: t('schools'), to: '/schools' },
          { name: t('edit school'), active: true }
        ]}
      />
      <h1 className={styles.mainHeading}>{t('edit school')}</h1>
      <Formik
        initialValues={existingSchool}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {props => (
          <Form>
            <SchoolForm {...props} FieldWrapperComponent={Panel} type="edit">
              <APIErrorMessage error={addingSchoolErrorMessage} />
              <Grid container direction="row">
                <Button size="md" type="button" level="tertiary" onClick={navigateToSchools}>
                  {t('back')}
                </Button>
                <Button
                  size="md"
                  type="submit"
                  spacing={{ margins: { sm: 'left' } }}
                  loading={savingSchool}
                  disabled={savingSchool}
                >
                  {t('save')}
                </Button>
              </Grid>
            </SchoolForm>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default EditSchool
